@import "../../../../../theme/styles/media"
@import "../../../../../theme/styles/variables"
@import "../../../../../theme/styles/functions"

.select-cards
    @apply flex
    & .selectors
        position: relative
        align-items: flex-start
    &__left, &__right
        flex: 1
        padding-top: 48px
        padding-bottom: 48px
    &__left
        padding-right: 45px
        @apply border-r border-border
    &__right
        padding-left: 45px
    &__delete
        position: absolute
        right: -10px
        top: calc(50% + 16px)
        cursor: pointer
        transform: translate(100%, -50%)
        height: fit-content
        +max-w($lg)
            right: 0
            transform: translate(0, -50%)
        +max-w($sm)
            background-color: $error-color
            border-radius: 50%
            padding: 6px
            top: 0
            left: 50%
            right: auto
            transform: translate(-50%, -30%)
            & svg
                color: white
    +max-w($lg)
        flex-direction: column
        &__left
            border: none
            padding-right: 0

        &__right
            padding-left: 0
            padding-top: 0
        & .custom-number-input
            width: calc(100% - 40px) !important
    +max-w($sm)
        & .selectors
            padding: 24px
            border-radius: 11px
            @apply bg-paper border border-gray-200
            & .custom-number-input
                width: 100% !important

.form-personal
    +max-w($lg)
        flex-direction: column


.methods-table
    @apply flex flex-col gap-2.5
    &__item
        padding: 10px
        display: flex
        align-items: center
        border-radius: 11px
        @apply border
        +max-w(500)
            gap: 0
        &--left
            flex: 1
            @apply flex items-center gap-2.5
        &--right
            @apply flex items-center gap-2.5
        +max-w(500)
            flex-direction: column
            align-items: flex-start
            &--right
                width: 100%
                justify-content: space-between
                & .table-search
                    width: 100% !important
                    display: none
        +max-w(390)
            gap: 10px
            &--right
                flex-direction: column
                justify-content: space-between
                align-items: flex-start
                gap: 10px
                & button
                    width: 100%
.denomination-number
    grid-template-columns: repeat(2, 1fr)
    +max-w(428)
        grid-template-columns: repeat(1, 1fr)

.denomination-form
    & > .currency
        & .selector-custom__contained
            border-left-width: 0
            border-top-left-radius: 0
            border-bottom-left-radius: 0
    & > .card-denomination
        & > .custom-input__input
            border-top-right-radius: 0
            border-bottom-right-radius: 0

.with-ol
    & ul
        list-style: unset !important
        margin: 14px 0 !important
        padding: 0 0 0 30px !important
        