@import "theme/styles/media"
@import "theme/styles/variables"
@import "theme/styles/functions"

@tailwind base
@tailwind components
@tailwind utilities

@font-face
  font-family: "Popins"
  src: url(./assets/Fonts/Poppins-Regular.ttf)
@font-face
  font-family: "Popins-M"
  src: url("./assets/Fonts/Poppins-Medium.ttf")
@font-face
  font-family: "Popins-B"
  src: url("./assets/Fonts/Poppins-Bold.ttf")
@font-face
  font-family: "Popins-S"
  src: url("./assets/Fonts/Poppins-SemiBold.ttf")
@font-face
  font-family: "Popins-D"
  src: url("./assets/Fonts/Poppins-Black.ttf")
@font-face
  font-family: "Roboto"
  src: url(./assets/Fonts/Roboto-Regular.ttf)
@font-face
  font-family: "Roboto-M"
  src: url("./assets/Fonts/Roboto-Medium.ttf")
@font-face
  font-family: "Roboto-B"
  src: url("./assets/Fonts/Roboto-Bold.ttf")
@font-face
  font-family: "Roboto-D"
  src: url("./assets/Fonts/Roboto-Black.ttf")
body
  margin: 0
  font-family: "Popins", sans-serif
body:has(.foxy-header__drawer--open)
  overflow: hidden
.margin-center
  margin: 0 auto

a
  cursor: pointer
  @apply text-main
  &:hover
    text-decoration: underline

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield
.foxy-paper
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.07)

.foxy-page-title
  text-align: center
  font-family: "Popins-B"
  font-size: 48px
  font-style: normal
  font-weight: 700
  line-height: 126.5%
  margin-bottom: 60px
  @apply text-text-blue

.foxy-card
  width: 350px
  height: 220px

.Toastify__toast
  border-radius: 14px
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.07) !important
  color: white !important
  &-icon
    width: 24px !important
    & svg
      fill: white
  &--success
    @apply bg-green-500 !important
  &--error
    @apply bg-red-500 !important
  &--warning

    @apply bg-orange-400 !important
  &--info
    @apply bg-blue-500 !important

.Toastify__close-button
  color: white !important
  opacity: 1 !important

strong
  font-family: Roboto-B
