@import "../../theme/styles/media"
@import "../../theme/styles/variables"
@import "../../theme/styles/functions"


.custom-modal
    z-index: 10000
    width: 100%
    height: 100vh
    left: 0
    top: 0
    opacity: 0
    transition: 0.15s
    display: flex
    align-items: center
    justify-content: center
    @apply fixed bg-black bg-opacity-35
    &__content
        opacity: 0
        transition: transform 0.15s, opacity 0.15s
        transform: translateY(-100px)
        padding: 20px
        @apply bg-white w-full sm:max-w-fit
        &-show
            opacity: 1
            transform: translateY(0)
    &-show
        opacity: 1
        transform: scale(1)
