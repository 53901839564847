@import "../../theme/styles/media"
@import "../../theme/styles/variables"
@import "../../theme/styles/functions"


.custom-tab
    display: flex
    @apply border-b-2 border-border
    +max-w($md)
        width: fit-content
    &__item
        height: 50px
        display: flex
        align-items: center
        justify-content: center
        padding: 0 30px
        position: relative
        cursor: pointer
        font-family: "Popins-S"
        white-space: nowrap
        @apply text-label
        &--active
            color: $main-color
            &::after
                position: absolute
                content: ''
                display: block
                width: 100%
                height: 20px
                bottom: -2px
                border-bottom: 2px solid $main-color
        &:hover:not(&--active)
            &::after
                position: absolute
                content: ''
                display: block
                width: 100%
                height: 20px
                bottom: -2px
                border-bottom: 2px solid $main-color
