@import "../../../theme/styles/media"
@import "../../../theme/styles/variables"
@import "../../../theme/styles/functions"


.custom-checkbox
    display: flex
    align-items: center
    gap: 10px
    cursor: pointer
    position: relative
    user-select: none
    width: fit-content
    &:has(+.custom-checkbox__error)
        & > input::after
            @apply border-red-500 !important
    & > input
        appearance: none
        height: 25px
        width: 25px
        min-width: 25px
        &::after
            position: absolute
            content: ""
            border-radius: 3px
            left: 0
            top: 0
            width: 25px
            height: 25px
            z-index: 0
            @apply border border-border
        &:checked::after
            background: url(/assets/icons/check.svg), $main-color
            background-position: center
            background-size: cover
            background-repeat: no-repeat
    & > span
        @apply text-label text-sm
    &__error
        position: absolute
        top: calc(100% + 4px)
        @apply text-sm text-red-500
.custom-checkbox__wrapper
    position: relative
