@import "../../theme/styles/media"
@import "../../theme/styles/variables"
@import "../../theme/styles/functions"

.selector-custom
  font-size: 16px
  color: $main-color
  outline: none
  height: 50px
  line-height: 38px
  padding: 0 0 0 20px
  display: flex
  justify-content: space-between
  align-items: center
  cursor: pointer
  gap: 10px
  max-width: 100%
  border-radius: 11px
  font-size: 14px
  box-sizing: content-box
  &__disabled
    background-color: #f5f5f5 !important
  &__error
    @apply border-red-500 !important
  &__error-message
    @apply text-red-500 text-sm
  &__list
    @apply flex flex-col
    &-wrapper
      flex: 1 1 0
      overflow-y: auto
      background-color: white
  & .dropdown-icon
    padding-right: 22px
    flex-shrink: 0
  & > div:first-child
    display: flex
    align-items: center
    gap: 10px
    width: 100%
    max-width: calc(100% - 40px)
    & > span
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
  &__text
    padding: 0 20px 0 0
    & input
      appearance: none
      background-color: transparent
      max-width: 20px
  &__contained
    @apply border border-border bg-white
    & input
      outline: none
      color: black
    &:hover
      @apply border

.select-custom__list
  background-color: white
  box-shadow: 25px 25px 40px 0 rgba(16, 39, 83, 0.1)
  z-index: 100000
  height: auto
  border-radius: 11px
  max-height: 400px !important
  @apply border border-border
  &-item
    font-size: 14px
    line-height: 16px
    color: #0d1118
    padding: 10px 16px
  &-item:hover:not(.selector-custom__selected)
    color: white
    cursor: pointer
    background-color: $main-color

.selector-custom__selected
  background-color: rgba($main-color, 0.2)
  color: $main-color
  cursor: pointer
.selector-custom__full-width
  width: 100%
.select-custom__list .select-custom__list-item:first-child
  margin-top: 10px

.select-custom__list .select-custom__list-item:last-child
  margin-bottom: 10px
.selector-custom__icon--up
  transform: rotate(180deg)
