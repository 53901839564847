@import "../../theme/styles/media"
@import "../../theme/styles/variables"
@import "../../theme/styles/functions"


.foxy-footer
    background-color: $main-color
    color: white
    height: fit-content
    display: flex
    flex-direction: column
    +max-w($lg)
        flex-direction: column
        align-items: center
        gap: 20px
    & .container
        padding-top: 64px
        padding-bottom: 64px
    &__wrapper
        display: flex
        +max-w($lg)
            flex-direction: column
            padding-bottom: 10px
    &__left
        margin-right: 76px
        & p
            width: 340px
            min-width: 340px
            line-height: 170.5%
        +max-w($lg)
            margin-bottom: 40px
            margin-right: 0
            display: flex
            flex-direction: column
            align-items: center
            & p
                width: 100% !important
                display: none
    &__right
        display: flex
        justify-content: space-between
        width: 100%
        & .footer-links
            &__list
                display: flex
                flex-direction: column
                gap: 10px
        +max-w($lg)
            flex-direction: column
            align-items: center
            gap: 20px
            & .footer-links
                display: flex
                flex-direction: column
                width: 100%
                text-align: center
                &__list
                    display: flex
                    flex-direction: column
                    align-items: center

        & .footer-links
            display: flex
            flex-direction: column
            & h3
                margin-bottom: 15px
            & a
                display: block
                width: fit-content
                font-family: Popins
                line-height: 170.5%
                @apply text-label
                &:hover
                    text-decoration: underline
.bottom-p
    display: none
    lineheight: 170.5%
    +max-w($lg)
        display: block
        text-align: center
