
$main-color: #0122A1
$error-color: #E51E1F
$error-color-light: #EC5252
$success-color: #14AE5C
$inherit-color: #c3ced7
$main-paper: #F5F5F5
$main-color-dark: #001A5D
$main-color-light: #002ebc

$mini: 376
$xs: 440
$sm: 640
$md: 768
$lg: 1024
$xl: 1280
$xxl: 1536
