@import "../../theme/styles/media"
@import "../../theme/styles/variables"
@import "../../theme/styles/functions"

.foxy-header
    background-color: $main-color
    height: 110px
    max-height: 110px
    display: flex
    align-items: center
    +max-w($lg)
        height: 80px
        max-height: 80px
        & > .container
            justify-content: space-between
    &__links
        display: flex
        justify-content: space-between
        &--left, &--right
            display: flex
            gap: 41px
        & a
            color: white
            text-decoration: none
        +max-w($lg)
            display: none
    &__buy-button
        +max-w($lg)
            display: none !important
    &__menu-button
        display: none
        & svg
            transition: 0.2s ease-in-out
        +max-w($lg)
            display: block
            cursor: pointer
            padding: 10px
            border-radius: 11px
            &:hover
                background-color: #ffffff1a
    &__drawer
        transition: all 0.2s ease-in-out
        position: fixed
        top: 80px
        right: 0
        background-color: $main-color
        height: calc(100vh - 80px)
        max-width: 320px
        width: 320px
        z-index: 10000
        transform: translateX(100%)
        +max-w($sm)
            max-width: 100%
            width: 100%
        &--links
            display: flex
            flex-direction: column
            align-items: flex-start
            padding: 30px 16px
            gap: 20px
            font-family: Popins-S
            & a
                color: white
        &--auth
            display: flex
            flex-direction: column
            gap: 20px
            align-items: flex-start
            & a
                text-align: left !important
        &--buy-button
            margin: 0 auto
            display: none !important
        &--open
            transform: translateX(0)
            box-shadow: -20px 0 40px 0 rgba(0, 0, 0, 0.1)
    &__menu-button:has(+.foxy-header__drawer--open) svg
        transform: rotate(90deg)
