@import "../../../theme/styles/media"
@import "../../../theme/styles/variables"
@import "../../../theme/styles/functions"

.order-card
    &__header
        margin: 0 auto
        display: flex
        flex-direction: column
        align-items: center
        width: max-content
        gap: 20px
        +max-w(505)
            width: 100%
        &--title
            color: white
            font-family: Popins-S
            font-size: 32px
            display: block
            border-radius: 32px
            text-align: center
            padding: 10px 60px
            width: 100%
            @apply bg-black
            +max-w(505)
                padding: 10px
                width: fit-content
                font-size: 24px
                border-radius: 11px
        &--info
            display: flex
            gap: 10px
            & svg
                min-width: 24px
.confirm-actions

    +max-w(750)
        width: 100%
        flex-direction: column
        gap: 20px
        & .flex
            width: 100%
            & button
                width: 100%
    +max-w($sm)
        & .flex:last-child
            flex-direction: column-reverse
            gap: 20px
            & button
                width: 100%
