@import "../theme/styles/media"
@import "../theme/styles/variables"
@import "../theme/styles/functions"

.foxy-layout
    display: flex
    flex-direction: column
    min-height: 100vh
    & main
        flex: 1
        padding: 60px 0 100px
        background: url('/assets/main-dots.png') no-repeat left 20%, url('/assets/main-dots.png') no-repeat right 60%, $main-paper
