@import "../../theme/styles/media"
@import "../../theme/styles/variables"
@import "../../theme/styles/functions"

.custom-button
    height: 50px
    padding: 0 32px
    font-size: 15px
    font-weight: 600
    display: flex
    align-items: center
    justify-content: center
    gap: 10px
    @apply rounded-full
    &__size-sm
        height: 40px
        padding: 0 22px
        font-size: 13px
    &__contained-primary
        background-color: $main-color
        color: white
        & svg
            color: white
        &:hover
            background-color: rgba($main-color, 0.9)
    &__contained-error
        background-color: $error-color
        color: white
        & svg
            color: white
        &:hover
            background-color: rgba($error-color, 0.9)
    &__contained-error-light
        background-color: $error-color-light
        color: white
        & svg
            color: white
        &:hover
            background-color: rgba($error-color-light, 0.9)
    &__contained-success
        background-color: $success-color
        color: white
        & svg
            color: white
        &:hover
            background-color: rgba($success-color, 0.9)
    &__contained-inherit
        background-color: $inherit-color
        color: $main-color
        & svg
            color: $main-color
        &:hover
            background-color: rgba($inherit-color, 0.3)
    &__outlined-inherit
        box-shadow: 0 0 0 2px $inherit-color inset
        color: $main-color
        & svg
            color: $main-color
        &:hover
            background-color: rgba($inherit-color, 0.3)
    &__outlined-primary
        box-shadow: 0 0 0 2px $main-color inset
        color: $main-color-dark
        & svg
            color: $main-color-dark
        &:hover
            background-color: rgba($main-color, 0.3)
    &__outlined-error
        box-shadow: 0 0 0 2px $error-color inset
        color: $error-color
        & svg
            color: $error-color
        &:hover
            background-color: rgba($error-color, 0.3)
    &__outlined-error-light
        box-shadow: 0 0 0 2px $error-color-light inset
        color: $error-color-light
        & svg
            color: $error-color-light
        &:hover
            background-color: rgba($error-color-light, 0.3)
    &__outlined-success
        box-shadow: 0 0 0 2px $success-color inset
        color: $success-color
        & svg
            color: $success-color
        &:hover
            background-color: rgba($success-color, 0.3)
    &__text-primary, &__text-inherit
        color: $main-color-dark
        padding: 0
        height: auto
        & svg
            color: $main-color-dark
        &:hover
            text-decoration: underline
