@import "../../../theme/styles/media"
@import "../../../theme/styles/variables"
@import "../../../theme/styles/functions"


.custom-input
    position: relative
    &__input
        border-radius: 11px
        padding: 0 20px
        gap: 15px
        @apply border border-border flex items-center bg-white
        &:has(input[type="hidden"])
            border: none
        &:has(>input:disabled)
            background-color: #f5f5f5
        &:focus-within:not(.custom-input__error)
            @apply border-main
        & > input, & > textarea
            outline: none
            min-height: 50px
            background-color: transparent
            resize: none
            font-size: 14px
            @apply appearance-none w-full
        & > textarea
            height: 100%
            padding: 10px 0

    &__error
        @apply border-red-500
    &__error-message
        position: absolute
        top: calc(100% + 4px)
        @apply text-red-500 text-sm
    &__label
        @apply text-sm text-label
